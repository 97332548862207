import React from 'react';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { CustomTextInput } from './TextInput';
import momentTimeZone from 'moment-timezone';

export default function DateInput({
  inputFormat = 'DD/MM/YYYY',
  fluid,
  sx,
  toolbarTitle = 'Selecione',
  disablePast,
  views = ['year', 'month', 'day'],
  openTo = 'day',
  ...rest
}) {
  const customSx = {
    width: fluid ? '100%' : 'auto',
    ...sx,
  };

  return (
    <LocalizationProvider
      dateAdapter={AdapterMoment}
      adapterLocale="pt-BR"
      dateLibInstance={momentTimeZone}
    >
      <MobileDatePicker
        {...rest}
        toolbarTitle={toolbarTitle}
        disablePast={disablePast}
        inputFormat={inputFormat}
        views={views}
        openTo={openTo}
        renderInput={(params) => (
          <CustomTextInput
            sx={customSx}
            {...params}
            error={!rest?.pristine && !rest?.valid}
          />
        )}
      />
    </LocalizationProvider>
  );
}

import React from 'react';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import buildForm from '../library/forms/buildForm';
import Settings from './Settings';
import { updateProfile } from '../../processes/profileProcesses';
import { getCurrentUser } from '../../selectors/userSelectors';
import SettingsProfileForm from './SettingsProfileForm';

const FORM_NAME = 'profileSettingsEdit';
const form = buildForm(FORM_NAME);

function SettingsContainer(props) {
  return (
    <Settings {...props}>
      <SettingsProfileForm {...props} />
    </Settings>
  );
}

function mapStateToProps(state) {
  const currentUser = getCurrentUser(state);
  const valueSelector = formValueSelector(FORM_NAME);
  const password = valueSelector(state, 'password');
  const passwordConfirmRequired = Boolean(password);

  return {
    initialValues: currentUser,
    imageUrl: form.getValue('imageUrl', state),
    currentUser,
    passwordConfirmRequired,
    emailNotificationsEnabled: valueSelector(
      state,
      'emailNotificationsEnabled',
    ),
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: updateProfile,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(SettingsContainer),
);

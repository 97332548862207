import React from 'react';
import { connect } from 'react-redux';
import buildForm from '../library/forms/buildForm';
import Settings from './Settings';
import { updateProfile } from '../../processes/profileProcesses';
import {
  getCurrentUser,
  getCurrentUserAvailableTimezones,
} from '../../selectors/userSelectors';
import SettingsGeneralForm from './SettingsGeneralForm';

const FORM_NAME = 'generalSettingsEdit';
const form = buildForm(FORM_NAME);

function SettingsContainer(props) {
  return (
    <Settings {...props}>
      <SettingsGeneralForm {...props} />
    </Settings>
  );
}

function mapStateToProps(state) {
  const currentUser = getCurrentUser(state);
  const timezones = getCurrentUserAvailableTimezones(state);
  const printerSizesOptions = [
    { name: 'Mini', value: 'mini' },
    { name: 'Padrão', value: 'standard' },
    { name: 'Grande', value: 'big' },
  ];
  const costPeriodsOptions = [
    { name: 'Trimestral', value: 'quarterly' },
    { name: 'Semestral', value: 'semesterly' },
    { name: 'Anual', value: 'annually' },
    { name: 'Desde sempre', value: 'unlimited' },
  ];

  return {
    initialValues: currentUser,
    currentUser,
    timezones,
    printerSizes: printerSizesOptions,
    costPeriods: costPeriodsOptions,
  };
}

export default connect(mapStateToProps)(
  form.connect({
    onSubmit: updateProfile,
    enableReinitialize: true,
    keepDirtyOnReinitialize: true,
  })(SettingsContainer),
);

import React from 'react';
import { spacing } from '../library/theme';
import Page from '../page/Page';
import Tabs from '../library/Tabs';

const contentStyle = {
  marginTop: spacing.m,
};

const tabs = [
  { label: 'Perfil', to: '/settings/profile' },
  { label: 'Geral', to: '/settings/general' },
];

export default function Settings(props) {
  return (
    <Page title="Configurações" back="/" headerType="secondary">
      <Tabs data={tabs}></Tabs>
      <div style={contentStyle}>{props.children}</div>
    </Page>
  );
}

import { IconButton, InputAdornment } from '@mui/material';
import React, { useCallback, useState } from 'react';
import { DateField, Form, TextField } from '../library/forms';
import theme, { decorationColors, spacing } from '../library/theme';
import SearchIcon from '@mui/icons-material/Search';
import ClearIcon from '@mui/icons-material/Clear';
import AddButton from '../library/AddButton';
import { Button, Grid, Modal, Separator, Title } from '../library';
import FilterListIcon from '@mui/icons-material/FilterList';

const wrapperSx = {
  display: 'flex',
  justifyContent: 'space-between',
};
const marginSx = {
  display: 'flex',
  flexDirection: 'column',
  marginBottom: spacing.xs,
};
const containerSx = {
  padding: spacing.s,
  display: 'flex',
};
const removeShadowSx = {
  '&:hover': {
    boxShadow: 'none',
  },
  width: '50%',
};
const iconSx = {
  height: 24,
  width: 24,
  color: decorationColors.decorationBrand,
};
const titleSx = { paddingBottom: spacing.xs };
const paperSx = { '.MuiDrawer-paper': { borderRadius: spacing.s } };
const filterButtonSx = {
  backgroundColor: theme.palette.add.main,
  ':hover': {
    backgroundColor: theme.palette.add.active,
  },
  ...removeShadowSx,
};
const birtdayFilterOptions = {
  views: ['month'],
  openTo: 'month',
  inputFormat: 'MMMM',
  width: '100%',
  fluid: true,
};

export default function CustomerSearchForm(props) {
  const {
    clearHandler,
    nameCont,
    onAddButton,
    handleSubmit,
    error,
    onClearFilters,
  } = props;
  const [showFilters, setShowFilters] = useState(false);
  const toggleFilters = useCallback(() => {
    setShowFilters(!showFilters);
  }, [showFilters]);
  const hideFilters = useCallback(() => {
    setShowFilters(false);
  }, []);

  return (
    <Form onSubmit={handleSubmit} error={error}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={9} style={wrapperSx}>
          <TextField
            name="nameCont"
            placeholder="Pesquisar por nome..."
            width="100%"
            fluid
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
              endAdornment: !!nameCont ? (
                <InputAdornment position="end">
                  <IconButton edge="end" onClick={clearHandler}>
                    <ClearIcon />
                  </IconButton>
                </InputAdornment>
              ) : null,
            }}
          />
        </Grid>
        <Grid item xs={3} style={wrapperSx}>
          <IconButton style={{ padding: 4 }} onClick={toggleFilters}>
            <FilterListIcon style={iconSx} />
          </IconButton>
          <AddButton onClick={onAddButton} />
        </Grid>
      </Grid>

      <Modal
        anchor="right"
        open={showFilters}
        onClose={hideFilters}
        sx={paperSx}
        maxWidth="260px"
        title="Filtros"
      >
        <Grid container sx={containerSx}>
          <Grid item xs={12} sx={marginSx}>
            <Title sx={titleSx} variant="h7">
              Data de nascimento entre:
            </Title>
          </Grid>
          <Grid container spacing={2}>
            <Grid item xs={5}>
              <DateField
                name="birthdayFrom"
                label="Data Inicial"
                {...birtdayFilterOptions}
              />
            </Grid>
            <Grid item xs={5}>
              <DateField
                name="birthdayTo"
                label="Data Final"
                {...birtdayFilterOptions}
              />
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Separator />
          </Grid>
          <Grid item xs={12} sx={{ display: 'flex', gap: spacing.xs }}>
            <Button sx={removeShadowSx} onClick={onClearFilters}>
              Limpar filtros
            </Button>
            <Button sx={filterButtonSx} onClick={hideFilters}>
              Filtrar
            </Button>
          </Grid>
        </Grid>
      </Modal>
    </Form>
  );
}

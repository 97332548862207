import React from 'react';
import { Route } from 'react-router-dom';
import APP from '../components/APP';
import CustomerPage from '../components/customers/CustomerPage';
import CustomersPageContainer from '../components/customers/CustomersPageContainer';
import CustomerOrdersPageContainer from '../components/customers/CustomerOrdersPageContainer';
import DashboardContainer from '../components/dashboard/DashboardContainer';
import InventoriesPageContainer from '../components/inventory/InventoriesPageContainer';
import NotificationsPageContainer from '../components/notifications/NotificationsPageContainer';
import OrderFormContainer from '../components/orders/OrderFormContainer';
import OrdersPageContainer from '../components/orders/OrdersPageContainer';
import PricingContainer from '../components/pricing/PricingContainer';
import PricingDownload from '../components/pricing/PricingDownload';
import ProductPage from '../components/products/ProductPage';
import ProductsPageContainer from '../components/products/ProductsPageContainer';
import RecipePage from '../components/recipes/RecipePage';
import RecipesPageContainer from '../components/recipes/RecipesPageContainer';
import SignInFormContainer from '../components/signIn/SignInFormContainer';
import ExpensesPageContainer from '../components/statements/ExpensesPageContainer';
import RevenuesPageContainer from '../components/statements/RevenuesPageContainer';
import RevenuePage from '../components/statements/RevenuePage';
import ExpensePage from '../components/statements/ExpensePage';
import StatementsContainer from '../components/statements/StatementsContainer';
import StatementsProductsContainer from '../components/statements/StatementsProductsContainer';
import StatementsPage from '../components/statements/StatementsPage';
import SuppliesPageContainer from '../components/supplies/SuppliesPageContainer';
import SupplyPage from '../components/supplies/SupplyPage';
import TaskFormContainer from '../components/tasks/TaskFormContainer';
import TasksPageContainer from '../components/tasks/TasksPageContainer';
import SuppliesImportPageContainer from '../components/supplies/SuppliesImportPageContainer';
import BudgetContainer from '../components/orders/BudgetContainer';
import PricingCalculatorFormContainer from '../components/pricing/PricingCalculatorFormContainer';
import SettingsProfileContainer from '../components/settings/SettingsProfileContainer';
import SettingsGeneralContainer from '../components/settings/SettingsGeneralContainer';

export default function AppRoutes(props) {
  return (
    <APP {...props}>
      <Route path="/" component={DashboardContainer} exact />
      <Route path="/signin" component={SignInFormContainer} exact />

      {/* SUPPLY ROUTES */}
      <Route
        path="/catalogs/supplies/"
        component={SuppliesPageContainer}
        exact
      />
      <Route path="/catalogs/supplies/new" component={SupplyPage} exact />
      <Route
        path="/catalogs/supplies/import"
        component={SuppliesImportPageContainer}
        exact
      />
      <Route
        path="/catalogs/supplies/:supplyId/edit"
        component={SupplyPage}
        exact
      />
      <Route
        path="/catalogs/supplies/:supplyId/inventories"
        component={InventoriesPageContainer}
        exact
      />

      {/* RECIPE ROUTES */}
      <Route path="/catalogs/recipes/" component={RecipesPageContainer} exact />
      <Route path="/catalogs/recipes/new" component={RecipePage} exact />
      <Route
        path="/catalogs/recipes/:recipeId/edit"
        component={RecipePage}
        exact
      />

      {/* PRODUCTS ROUTES */}
      <Route
        path="/catalogs/products/"
        component={ProductsPageContainer}
        exact
      />
      <Route path="/catalogs/products/new" component={ProductPage} exact />
      <Route
        path="/catalogs/products/:productId/edit"
        component={ProductPage}
        exact
      />

      {/* CUSTOMERS ROUTES */}
      <Route path="/customers" component={CustomersPageContainer} exact />
      <Route path="/customers/new" component={CustomerPage} exact />
      <Route
        path="/customers/:customerId/edit"
        component={CustomerPage}
        exact
      />
      <Route
        path="/customers/:customerId/orders"
        component={CustomerOrdersPageContainer}
        exact
      />

      {/* STATEMENTS ROUTES */}
      <Route path="/statements" exact>
        <StatementsPage>
          <StatementsContainer />
        </StatementsPage>
      </Route>
      <Route path="/statements/revenues/new" component={RevenuePage} exact />
      <Route
        path="/statements/revenues/:statementId/edit"
        component={RevenuePage}
        exact
      />
      <Route path="/statements/revenues" exact>
        <StatementsPage>
          <RevenuesPageContainer />
        </StatementsPage>
      </Route>
      <Route path="/statements/expenses/new" component={ExpensePage} exact />
      <Route
        path="/statements/expenses/:statementId/edit"
        component={ExpensePage}
        exact
      />
      <Route path="/statements/expenses" exact>
        <StatementsPage>
          <ExpensesPageContainer />
        </StatementsPage>
      </Route>
      <Route
        path="/statements/products/:dates"
        component={StatementsProductsContainer}
        exact
      />

      {/* ORDERS ROUTES */}
      <Route path="/orders" component={OrdersPageContainer} exact />
      <Route path="/orders/new" component={OrderFormContainer} exact />
      <Route
        path="/orders/:orderId/edit"
        component={OrderFormContainer}
        exact
      />
      <Route path="/pedidos/:id" component={BudgetContainer} exact />

      {/* TASKS ROUTES */}
      <Route path="/tasks" component={TasksPageContainer} exact />
      <Route path="/tasks/new" component={TaskFormContainer} exact />
      <Route path="/tasks/:taskId/edit" component={TaskFormContainer} exact />

      {/* SETTINGS ROUTES */}
      <Route path="/settings/general" exact>
        <SettingsGeneralContainer />
      </Route>

      <Route path="/settings/profile" exact>
        <SettingsProfileContainer />
      </Route>

      {/* NOTIFICATIONS ROUTES */}
      <Route
        path="/notifications"
        component={NotificationsPageContainer}
        exact
      />

      {/* PRICING ROUTES */}
      <Route path="/pricing" component={PricingContainer} exact />
      <Route path="/pricing/download" component={PricingDownload} exact />
      <Route
        path="/pricing/calculator"
        component={PricingCalculatorFormContainer}
        exact
      />
    </APP>
  );
}
